import image2 from '../components/img/JavaScript Test.png'
import image3 from '../components/img/Weather forecast.png';
import image4 from '../components/img/readme generator.png';
import image5 from '../components/img/team members.png';
import image6 from '../components/img/CMS.png';
import image7 from '../components/img/the tech blog.png';
import image10 from '../components/img/portfolio MERN.png';
import image11 from '../components/img/image11.png';
import image12 from '../components/img/bitelandia.png';
import image13 from '../components/img/AJ.png';
//import image14 from '../components/img/yc_website.png';
import image15 from '../components/img/e-commerce-tmp.png';
import image16 from '../components/img/alworking.gif';
import { useState } from 'react';
import {useEffect } from 'react';
import cardsData from '../portfolio.json'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default function Portfolio() {

const [position, setPosition] = useState(0);
const [count, setCount] = useState(0);
const [text, setText] = useState(false);
const [classmore, setTextmore] = useState("read-more-less");
const [expandedCardIndex, setExpandedCardIndex] = useState(null);

const toggleExtendedText = (index) => {
  setExpandedCardIndex(expandedCardIndex === index ? null : index);
};


const myimage=[image16,image12,image13,image15]

  // Carousel settings
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    touchMove: true,
    autoplay: false,
    className: 'my-carousel cards',

  };
useEffect(() => {
  if(text === false) {
    setTextmore("read-more-less");
  } else {
    setTextmore("nodisplay-more");
  }
}, [text]);

const decrementcalc = ()=> {
var counti = count
if((counti>0)&&(counti<=7)){
setPosition(counti-1)
setCount(count-1)  
}else{
setPosition(7)
setCount(7)
}
};

const incrementcalc = () =>{

var counti = count
if((counti>=0)&&(counti<7)){
setPosition(counti+1)
setCount(count+1)



}else{
setPosition(0)
setCount(0)

}

};

return (
<div>

<h2 className='subtitlepage'>
Highlighted Projects
</h2>
<div>
  
<div className='container-carousel'>
<Slider {...settings} className='my-carousel'>
      {myimage&&cardsData.map((card, index) => (
        <div className="cards" key={index}>
          <div>
            <img src={myimage[index]} alt="decorative" />
          </div>
          <h3>
            <a href={card.link} target="_blank" rel="noreferrer">
              {card.title} 🔗
            </a>
          </h3>
          <p>{card.description}</p>
         
          {expandedCardIndex === index && <p>{card.extendedText}</p>}
          <div className='rlandMbtn'>
          <button className='rlandMbtn'onClick={() => toggleExtendedText(index)}>
            {expandedCardIndex === index ? 'Read Less' : 'Read More'}
          </button>
          </div>
      
          <p>
            <strong>Tags:</strong> {card.tags.join(', ')}
          </p>
        </div>
      ))}
        </Slider>
    </div>

</div>


<h2 className='subtitlepage'>Miniprojects</h2>


<div className="cardmp-container">
      <div className="buttonmp-container">
        <button className='buttonmp'onClick={decrementcalc}>&#8656;</button>
        <button className='buttonmp' onClick={incrementcalc}>&#8658;</button>
      </div>
      {/* Carousel items... */}
      {(position === 0) && (
        <div className="cardsmp">
          <img src={image4} alt="decorative"></img>
      <h3><a href="https://github.com/jav8319/readme-generator-jav2022.git" target='_blank' rel='noreferrer'>Readme Generator🔗</a></h3>
      <p>
      This application must be run from the terminal. It creates a readme document dinamically, 
      the source code uses "inquirer" npm package to capture user's input and "fs.writeflile" 
      to create the readme file.<br></br><br></br> 
      Tags: Node.js(inquierer)</p>
        </div>
      )}
      {(position === 1) && (
        <div className="cardsmp">
        <img src={image3} alt="decorative"></img>
      <h3><a href="https://jav8319.github.io/scattered_on/" target='_blank' rel='noreferrer'>Weather Forecast🔗</a></h3>
      <p>
      This application retrieves weather data for a given city from an external server through an API call. 
The city must be typed in 
the search field, the last five searchs are stored and they can be easily
accessed by clicking on the buttom labeled with the city's name.<br></br><br></br> 
Tags: JavaScript(API call, Bootstrap), HTML,CSS</p>
        </div>
      )}
      {(position === 2) && (
        <div className="cardsmp">
        <img src={image2} alt="decorative"></img>
      <h3><a href="https://jav8319.github.io/sunshine-path/" target='_blank' rel='noreferrer'>JavaScript Test🔗</a></h3>
      <p>
      When open the website the user can take a knowlege test of JavaScript which must be completed within a time limit.
The code includes an interval function to set the time limit to answer the test.
The score is stored in the local storage.<br></br><br></br> 
Tags: JavaScript(interval function), HTML</p>
        </div>
      )}
      {(position === 3) && (
        <div className="cardsmp">
      <img src={image5} alt="decorative"></img>
      <h3><a href="https://github.com/jav8319/teamboard_ja22.git" target='_blank' rel='noreferrer'>Team Members🔗</a></h3>
      <p>
      This application creates an Html document dinamically by capturing data from the user using "inquierer" npm package.  It must be run 
from the terminal. The html document contains a team of work that can include manager, 
engineer or an intern. Each member is created by using object orientaion programing.<br></br><br></br> 
Tags: Node.js inquierer, object orientaion programming , css, HTML</p>
        </div>
      )}
      {(position ===4) && (
        <div className="cardsmp">
           <img src={image6} alt="decorative"></img>
      <h3><a href="https://github.com/jav8319/CMS_app_jm22.git" target='_blank' rel='noreferrer'>Content Management Systems App🔗</a></h3>
      <p>
      This application must be run from the terminal. It can be used to store data from employees. All the data is stored in a SQL database.
New employees,departments and roles can be created and be displayed.Employee's role can be modified too.<br></br><br></br> 
Tags:SQL(sequilize), Node.js</p>
        </div>
      )}
      {(position ===5) && (
        <div className="cardsmp">
           <img src={image7} alt="decorative"></img>
      <h3><a href="https://secret-tundra-30697.herokuapp.com/" target='_blank' rel='noreferrer'>The tech blog🔗</a></h3>
      <p>
      This is a Full stack application featuring a login/logout interface. It can be used to post technology content and comment on other users's posts.
Data is stored in a sQl database. Users must signup to post and comment.<br></br><br></br> 
Tags: SQL(sequilize), Handlebars, Express, Node.js HTML, CSS, Login/logout interface</p>
        </div>
      )}
      {(position ===6) && (
        <div className="cardsmp">
      <img src={image10} alt="decorative"></img>
      <h3>My Portfolio</h3>
      <p>
      This website uses MERN stack technology. React  is integrated with apolloserver in the front end, the form in the Contact tab writes data into a MongoDb database.<br></br><br></br> 
Tags: MERN stack</p>
        </div>
      )}
      {(position ===7) && (
        <div className="cardsmp">
      <img src={image11} alt="decorative"></img>
      
      <h3><a href="https://lit-temple-40675.herokuapp.com/" target='_blank' rel='noreferrer'>Online retail store🔗</a></h3>
      <p >
      A robust and highly versatile e-commerce web application template, leveraging MERN stack technology. This adaptable 
      template is designed for purchasing goods online, featuring a user-friendly login/logout interface and a comprehensive 
      shopping cart system.
      <span>
        <code className={classmore} onClick={() => setText(!text)}>..read more</code>
      </span><br></br>
      {text && (
      
      <span>        This application is architected with most calculations handled on the backend, 
      which provides an efficient, streamlined user experience. An administrative 
      interface exclusive for "owner" level users is available, offering capabilities 
      to add products, update product images, modify purchase order statuses, manage 
      product stock. Unique user roles such as "owners" and "users" are distinguished
       through an "access" field in the authmiddleware file, ensuring appropriate access and
        functionalities. Data interaction is facilitated through the integration of React 
        with Apollo Server. While the current implementation uses "react-router-dom" sparingly, 
        it is incorporated with future considerations for an easy integration with Stripe for 
        automatic online payments. Currently, payment can be processed by providing a payment 
        link to customers.Multiple collections are involved in order creation, displayed under 
        the "orders" tab in the administrator interface. On the frontend, product categories 
        can be filtered for a tailored shopping experience using the React useState hook and the 
        "filter" method.<br></br>

Please note that this is a template, designed with adaptability in mind. It can be tailored to 
match specific business needs and preferences. Test credentials are provided upon opening the app 
for a first-hand experience of its functionalities.<code className='read-more-less'onClick={() => setText(false)}>...read less</code></span>

      
   
    
  )}
    </p>
    <p>Tags: MERN stack, Google Cloud,</p>
        </div>
      )}
      
    </div>

</div>
)
}



